import { FC } from 'react';
import IconProps from './IconProps';

// LEGACY ICON
const SupervisoryAuthIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B' } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.36479 9.48687C6.9898 9.1093 6.37907 9.1093 6.00408 9.48687C5.63272 9.8608 5.63272 10.4643 6.00408 10.8383L8.20161 13.0509C8.38076 13.2315 8.62381 13.3331 8.87732 13.3333C9.00551 13.3312 9.13195 13.3031 9.24913 13.2507C9.3663 13.1983 9.47183 13.1227 9.55941 13.0285L14.0246 8.59345C14.4098 8.2108 14.4017 7.58515 14.0065 7.21273C13.6306 6.85837 13.0417 6.86481 12.6735 7.2273L8.85501 10.9874L7.36479 9.48687Z"
        fill={accentColor}
      />
      <path
        d="M18.9243 10.0031C18.926 7.98151 18.2416 6.01918 16.9831 4.43709C15.7246 2.855 13.9665 1.74681 11.9963 1.29377C10.0262 0.840734 7.96057 1.06968 6.13742 1.94315C4.31427 2.81662 2.84149 4.28292 1.95999 6.10219C1.07848 7.92147 0.840428 9.98604 1.28477 11.9582C1.72911 13.9303 2.82954 15.6933 4.40606 16.9588C5.98258 18.2243 7.94187 18.9173 9.96345 18.9245C11.985 18.9317 13.9492 18.2527 15.5347 16.9986L20.2156 21.6794C20.6177 22.0815 21.2696 22.0815 21.6717 21.6794C22.0738 21.2773 22.0738 20.6254 21.6717 20.2233L16.9909 15.5425C18.244 13.9682 18.9257 12.0152 18.9243 10.0031ZM9.99536 16.8715C8.63692 16.8715 7.30898 16.4687 6.17947 15.714C5.04997 14.9593 4.16962 13.8866 3.64977 12.6315C3.12991 11.3765 2.99389 9.99547 3.25891 8.66313C3.52393 7.33079 4.17809 6.10695 5.13865 5.14639C6.09922 4.18582 7.32306 3.53167 8.6554 3.26665C9.98774 3.00163 11.3688 3.13764 12.6238 3.6575C13.8788 4.17735 14.9515 5.0577 15.7062 6.1872C16.461 7.31671 16.8638 8.64465 16.8638 10.0031C16.8638 11.8247 16.1402 13.5717 14.8521 14.8598C13.564 16.1479 11.817 16.8715 9.99536 16.8715Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SupervisoryAuthIcon;
